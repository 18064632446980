/* 主要通过`firstStrategyGoodsNum`标识是否进行预请求 */
import { getStoreItems } from 'public/src/pages/store_pages/utils/api.js'
import { parseQueryString } from '@shein/common-function'
import { fetchProductListApi } from '@/public/src/services/pre_requests/productList'

let promise = null

/**
 * @description: 触发预请求列表数据
 */
export const triggerPreFetchStoreProductList = () => {
  promise = promise || preFetchProductList()
}

/**
 * @description: 获取预请求列表数据
 * @returns {Promise}
 * */
export const getPreFetchStoreProductList = () => {
  if (!promise) {
    triggerPreFetchStoreProductList()
  }
  return promise
}


export function isStorePage () {
  if (typeof window === 'undefined' || !window.gbRawData || !window.gbRawData.results) return false
  return window.gbRawData?.results?.cat_info?.type === 'store'
}

/**
 * @description: 预请求列表数据
 * @param 参数从gbRawData上获取
 * */
async function preFetchProductList() {
  if (typeof window === 'undefined') return Promise.resolve()
  if (typeof gbRawData === 'undefined') return Promise.resolve()
  if (!gbRawData?.results?.firstStrategyGoodsNum || gbRawData?.results?.firstStrategyGoodsNum <= 0) return Promise.resolve() // 未配置首屏商品数量
  if (!isStorePage()) return Promise.resolve()
  // 禁止浏览器滚动上一次的位置
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }


  const request = {
    type: 'firstLoad',
    query: {
      page: 1, 
      prePureGoods: 1, // 只请求商品数据
      ...parseQueryString(location.search)
    },
    loading: false
  }
  const catInfo = gbRawData?.results.cat_info || {}
  if(gbRawData?.results?.isStoreItemBff){
    const atomicParams = gbRawData.results?.atomicParams || {}
    const data = await fetchProductListApi(
      { catInfo, searchKeywords: {}, atomicParams, viewedGoods: [], request },
      { isPreFetch: true }
    ).catch(() => ({}))
    if(data){
      data.isStoreItemBff = true
    }
    if(data && !data.cat_info){
      data.cat_info = request.query || {}
    }
    return data
  }
  const data = await getStoreItems({
    request,
    catInfo,
  }, 
  { isPreFetch: true }
  ).catch((e) => {
    console.error(e)
  })
  return data?.goods || []
}
