/**
 * @file 对齐controllers/product_list_bff/selling/products.js
 */

import { getCommonParams, getListMainParams } from './common.js'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'

// 商品接口入参
const getGoodsParams = (options) => {
  const {
    catInfo,
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const { 
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
    sort,
  } = getCommonParams(options)

  let params = {
    ...mainParams,
    adp: getGoodsAdp(query),
    quickship,
    filter,
    filter_cat_id: catId,
    filterBrandIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: filterMallCode || urlMallCode,
    sort: sort || 0,
    styleType: query.styleType || '',
    _type: 'selling',
    page: query.page || 1,
    limit: catInfo.limit || 120, 
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选接口入参（图文导航，属性，分类，tsp筛选）
const getFilterParams = (options) => {

  const {
    catInfo,
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const { 
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    chooseIds,
    filterBrandIds,
    urlMallCode,
    filterMallCode,
  } = getCommonParams(options)

  let params = { 
    ...mainParams,
    quickship,
    filter,
    filter_cat_id: catId,
    filterBrandIds,
    choosed_ids: chooseIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: filterMallCode || urlMallCode,
    page_name: catInfo.pageName,
    styleType: query.styleType || '',
    showAllCategory: '1',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    _type: 'selling',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云接口入参
const getCloudTagParams = (options) => {
  const {
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const { 
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    urlMallCode,
    filterMallCode,
  } = getCommonParams(options)

  let params = {
    ...mainParams,
    filter,
    filter_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    styleType: query.styleType || '',
    _type: 'selling',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

export const getSellingPointGenerateBffParams = (options) => {
  return {
    goodsParams: getGoodsParams(options),
    filterParams: getFilterParams(options),
    cloudTagParams: getCloudTagParams(options)
  }
}
