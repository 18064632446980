
import { getCommonParams, getListMainParams } from './common.js'
import { isSearch, isAllSearch, isStoreSearch, isBrandSearch, isTopTrendResultPage, fixSearchSource, cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'

// 主搜POSKEY
const getSearchPoskey = (catInfo) => {
  const isInListSearch = !(catInfo.sil_without_entry || !catInfo.sil_type) // 是否是列表页内搜索
  return !isInListSearch ? 'SearchPageSort' : 'ListSearchSort'
}


// context_info参数处理
const getContextInfo = ({ catInfo, query }) => {
  let srcIdentifier = query?.src_identifier_pre_search || ''
  let keyArr = ['on', 'cn', 'hz', 'ps', 'jc'] 
  let tempSrcIdentifierObj = {}
  keyArr.forEach(keyVal => {
    tempSrcIdentifierObj[`src_identifier_${keyVal}`] = ''
  })
  if (srcIdentifier && typeof srcIdentifier === 'string' ) {
    srcIdentifier.split('`').forEach(item => {
      let key = item.split('=')[0]
      if (keyArr.includes(key)) {
        tempSrcIdentifierObj[`src_identifier_${key}`] = item.split('=')[1]
      }
    })
  }

  let newSrcTabPageId = typeof catInfo?.src_tab_page_id === 'string' ? catInfo?.src_tab_page_id : ''

  return {
    ...tempSrcIdentifierObj,
    src_module: catInfo?.src_module || '',
    src_tab_page_id: newSrcTabPageId.replace(/\d+$/, ''),
  }
}

// 商品接口入参
const getGoodsParams = (options) => {

  const {
    catInfo,
    searchKeywords,
    request
  } = options
  
  const query = request.query || {}
  const { type, mainParams } = getListMainParams(options)

  const { 
    newSrcTabPageId,
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    mallCode,
    serviceType,
    forceNotCorrect,
    listCatId,
    storeCode,
    intentChannelId,
  } = getCommonParams(options)

  let params = {
    ...mainParams,
    quickship,
    filter,
    cat_id: catId,
    filterBrandIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    mall_code: mallCode,
    force_not_correct: forceNotCorrect,
    adp: getGoodsAdp(query),
    is_force: query.is_force || '',
    sort: query.sort || '',
    source: query.source || '',
    source_status: query.source_status || '',
    page: query.page || 1,
    limit: query.limit || 120,
    context_info: getContextInfo({ catInfo, query })
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      service_type: serviceType,
      list_cat_id: listCatId,
      force_suggest: searchKeywords?.double_lang_correct, // 双语纠错标识字段, 1: 是  0: 否,
      src_tab_page_id: newSrcTabPageId || window?.getSaPageInfo?.tab_page_id || '-',
      poskey: getSearchPoskey(catInfo),
    })

    if (isAllSearch(type, query)) { // 全站搜索
      Object.assign(params, {
        scene: 'all',
        search_source: fixSearchSource(query),
      })
    } else if (isTopTrendResultPage(type, query)) { // 趋势页搜索
      Object.assign(params, {
        scene: 'trendChannel',
        search_source: fixSearchSource(query),
      })
    } else if (isStoreSearch(type, query)) { // 店铺搜索
      Object.assign(params, {
        scene: 'store',
        store_code: storeCode,
        ignore_direct: 'true',
      })
    } else if (isBrandSearch(type, query)) { // 专题搜索
      Object.assign(params, {
        intent_channel_id: intentChannelId,
        ignore_direct: 'true',
        search_source: fixSearchSource(query),
      })
    }
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选接口入参（图文导航，属性，分类，tsp筛选，标签云）
const getFilterParams = (options) => {

  const {
    request
  } = options
  
  const query = request.query || {}
  const { type, mainParams } = getListMainParams(options)

  const { 
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    serviceType,
    forceNotCorrect,
    listCatId,
    forceSuggest,
    intentChannelId,
    urlMallCode,
    filterMallCode,
    filter_goods_infos
  } = getCommonParams(options)

  let params = {
    ...mainParams,
    filter_goods_infos,
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    chooseTag: tagIds,
    mallCode: filterMallCode,
    forceNotCorrect,
    cancelFilterTagIds: filterTagIds,
    needFilter: '1', // 是否需要filter数据：0 不需要 1 需要(默认) 
    needNavIndex: '0', // 是否需要图文导航数据：0 不需要 1 需要(默认)
    needTagCloud: '1', // 是否需要标签云数据：0 不需要 1 需要(默认)
    positionAbt: '', // 筛选信息流ABT
    maxDisc: '', // 最大折扣
    mixDisc: '', // 最小折扣
    enterMallCode: urlMallCode, // 入口默认的mallcode，用来屏蔽mall标签云
    cancelFilter: '', // 用户取消的筛选项
    chooseIds: query.choose_ids, // 已选中的分类id和其所有父类id用逗号拼接
    showAllCategory: '1',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
  }

  // 搜索(全站，店铺，专题)
  if (isSearch(type)) {
    Object.assign(params, {
      serviceType,
      listCatId,
      forceSuggest,
      poskey: getSearchPoskey(query)
    })
  }

  if (isAllSearch(type, query)) { // 全站搜索
    Object.assign(params, {
      scene: 'all', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
    })
  } else if (isStoreSearch(type, query)) { // 店铺搜索
    Object.assign(params, {
      scene: 'store', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
      storeCode: query.store_code
    })
  } else if (isBrandSearch(type, query)) { // 专题搜索
    Object.assign(params, {
      intentChannelId,
    })
  } else if (isTopTrendResultPage(type, query)) { // 趋势页搜索
    Object.assign(params, {
      scene: 'trendChannel', // 搜索场景,all:全站搜索;store:店铺内搜索，store_code必传，trendChannel:趋势频道内搜索
    })
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


export const getSearchGenerateBffParams = (options) => {
  return {
    goodsParams: getGoodsParams(options),
    filterParams: getFilterParams(options),
  }
}
// 店铺 search 搜索
export const getStoreSearchGenerateBffParams = (options) => {
  const query = options?.request?.query || {}
  const storeOptions = {
    store_code: query.store_code,
    scene: 'store',
  }
  if(query.rule_poskey){
    storeOptions.web_rule_poskey = query.rule_poskey
  }
  // 店铺页面itemtab adp 处理;
  const storeGoodsParams = {
    ignore_direct: 'true',
  }
  if(!!query.force_send_adp){
    storeGoodsParams.adp =  query.pic_nav_adp || query.adp
  }
  return {
    goodsParams: { ...getGoodsParams(options),  ...storeOptions, ...storeGoodsParams },
    filterParams: { ...getFilterParams(options), ...storeOptions },
  }
}
