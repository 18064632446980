import { PRODUCT_LIST_URL_API, PRODUCT_LIST_REQUEST_URL_MAP } from './const.js'

import schttp from 'public/src/services/schttp'
import { promiseAsyncAuto } from '../common/asyncAuto.js'
import { generateRealSelectBffFilterInfo } from './utils.js'

import { getFetchParams } from './params/index.js'

function getUrl({ catInfo }) {
  if (!catInfo) throw new Error('catInfo is required')
  if (catInfo?.type == 'picks') {
    return location.pathname
  }
  if (catInfo?.type == 'store') {
    return '/api/store/items/get'
  }
  return PRODUCT_LIST_URL_API
}

// 页面接口地址
const getBffUrl = ({ catInfo }) => {
  if (!catInfo) throw new Error('catInfo is required')
  const { type, sub_type } = catInfo
  const _type = type === 'store' ? sub_type : type
  const {
    SEARCH, REAL, SELEST,
    COMMON, DAILY_NEW, SELLING,
    SHEIN_PICKS,
  } = PRODUCT_LIST_REQUEST_URL_MAP
  switch (_type) {
    case 'search':
      return {
        goods: SEARCH.goods,
        filters: SEARCH.filters,
      }
    case 'entity':
      return {
        goods: REAL.goods,
        filters: REAL.filters,
        cloudTag: REAL.cloudTag,
      }
    case 'selection':
      return {
        goods: SELEST.goods,
        filters: SELEST.filters,
        cloudTag: SELEST.cloudTag,
        showRecentDays: COMMON.categoryDay,
      }
    case 'selling': {
      return {
        goods: SELLING.goods,
        filters: SELLING.filters,
        cloudTag: SELLING.cloudTag,
      }
    }
    case 'daily_new':
      return {
        goods: DAILY_NEW.goods,
        filters: DAILY_NEW.filters,
        cloudTag: DAILY_NEW.cloudTag,
        showRecentDays: COMMON.showRecentDays,
      }
    case 'picks':
      return {
        goods: SHEIN_PICKS.goods,
        filters: SHEIN_PICKS.filters,
        cloudTag: SHEIN_PICKS.cloudTag,
        navTabs: SHEIN_PICKS.navTabs,
      }

  }
}

const subTypeTotypeId = new Map([
  ['entity', 1],
  ['selection', 9]
])

// 商品列表
const bffProductRequest = (options) => {
  const method = options.method || 'POST'
  const url = getBffUrl(options)?.goods

  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })
}

// 分类筛选等
const bffFilterRequest = (options) => {
  const method = options.method || 'GET'
  const url = getBffUrl(options)?.filters
  
  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })
}

// 标签云
const bffCloundTagRequest = (options) => {
  const method = options.method || 'GET'
  const url = getBffUrl(options)?.cloudTag
  
  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })
}

// 类目
const bffNavTabsRequest = async (options) => {
  const method = options.method || 'GET'
  const url = getBffUrl(options)?.navTabs

  return await schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  })
}

const bffCategoryDayTagRequest = (options) => {
  const method = options.method || 'GET'
  const url = getBffUrl(options)?.showRecentDays
  
  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })
}

async function getBffNavTabsRequest({ config, requertParams, isSheinPicks }) {
  const navTabRes = isSheinPicks ? await bffNavTabsRequest({
    ...config,
    params: requertParams.navParams
  }) : {}

  let picksMenuList = []

  if (navTabRes.code === '0' && navTabRes.info?.categories?.length > 0) {
    picksMenuList = navTabRes.info.categories.map((item) => {
      return {
        adp: item.adp,
        id: item.cat_id,
        title: item.cat_name,
        image: item.image,
        hrefTarget: item.cat_id,
        hrefType: Number(item.type) === 1 ? 'realOne' : 'itemPicking',
        catChecked: true,
      }
    })

    return {
      curlCommand: navTabRes?.curlCommand || '',
      ...navTabRes.info,
      picksMenuList,
    }
  }

  return {}
}

// Warn: 非泛列表页面，请求写到自己链路里
/**
 * @description: 获取商品列表
 * @param {Object} config 请求参数
 * @param {Object} config.catInfo 分类信息
 * @param {Object} config.searchKeywords 搜索关键词
 * @param {Object} config.atomicParams 原子参数
 * @param {Object} config.viewedGoods 已查看商品
 * @param {Object} config.request 请求参数
 *
 */
export async function fetchProductListApi(config, options = {}) {
  const requertParams = getFetchParams(config)

  // 是否走融合
  const useBffProducts = requertParams?.nodeParams?.useBffProducts

  // 首屏请求商品数据
  const isBffPrePureGoods = useBffProducts && config.request?.query?.prePureGoods === 1 && config.request?.query?.page === 1
  const isSheinPicks = config.catInfo?.type === 'picks'
  const isSelectAction = config.request?.type !== 'pageChange' && !isBffPrePureGoods

  let requertQueue = {}
  if (!isBffPrePureGoods) {

    // shein-picks 先融合 ssr 后面再融合 xhr 临时处理
    if (requertParams.nodeParams.type === 'picks') {
      requertParams.nodeParams._is_shein_picks_node_xhr = true
      // node请求
      requertQueue.nodeRes = (cb) => {
        schttp({
          url: getUrl(config),
          params: requertParams.nodeParams,
          ...options
        }).then((res) => {
          cb(null, res)
        })
      }
    } else {
      // node请求
      requertQueue.nodeRes = (cb) => {
        schttp({
          url: getUrl(config),
          params: requertParams.nodeParams,
          ...options
        }).then((res) => {
          cb(null, res)
        })
      }
    }
  }

  let bgBanner = {}
  let picksMenuList = []
  let modifiedQueryParams = {}
  let navInfoRes = {}
  let sheinPicksTypeParams = {}

  if (requertParams.navParams) {
    navInfoRes = await getBffNavTabsRequest({ config, requertParams, isSheinPicks })

    bgBanner = navInfoRes.background || {}
    if (bgBanner?.image && bgBanner.image.src) {
      bgBanner = {
        ...bgBanner,
        ...bgBanner.image,
        imgSrc: bgBanner.image.src,
      }
    }

    picksMenuList = navInfoRes.picksMenuList || []

    if (picksMenuList?.length) {
      const firstItem = picksMenuList[0]
      if (firstItem.hrefType === 'realOne') {
        modifiedQueryParams.sub_type = 'entity'
        modifiedQueryParams.cat_id = firstItem.id
        modifiedQueryParams.entity_id = firstItem.id
      } else {
        modifiedQueryParams.sub_type = 'selection'
        modifiedQueryParams.select_id = firstItem.id
      }

      // 赋默认值
      modifiedQueryParams.adp = firstItem.adp
      modifiedQueryParams.picksType = navInfoRes.pageId
      modifiedQueryParams.picks_id = firstItem.id

      sheinPicksTypeParams = {
        type: subTypeTotypeId.get(modifiedQueryParams.sub_type),
      }
    }
  }


  // 商品
  if (requertParams.goodsParams) {
    requertQueue.goods = (cb) => {

      if (config.request && config.request.query) {
        config.request.query = {
          ...config.request.query,
          ...modifiedQueryParams
        }
      }

      bffProductRequest({
        ...config,
        method: requertParams.method,
        params: {
          ...requertParams.goodsParams,
          ...sheinPicksTypeParams
        },
        cb
      })
    }
  }


  // 筛选
  if (requertParams.filterParams && isSelectAction) {
    requertQueue.filters = (cb) => {

      if (config.request && config.request.query) {
        config.request.query = {
          ...config.request.query,
          ...modifiedQueryParams
        }
      }

      bffFilterRequest({
        ...config,
        method: requertParams.method,
        params: {
          ...requertParams.filterParams,
          ...sheinPicksTypeParams
        },
        cb
      })
    }
  }

  // 标签云
  if (requertParams.cloudTagParams && isSelectAction) {
    requertQueue.cloudTags = (cb) => {
      bffCloundTagRequest({
        ...config,
        method: requertParams.method,
        params: {
          ...requertParams.cloudTagParams,
          ...sheinPicksTypeParams
        },
        cb
      })
    }
  }

  // 日期选择
  if (requertParams.categoryDayTagParams && isSelectAction) {
    requertQueue.dailyDates = (cb) => {
      bffCategoryDayTagRequest({
        ...config,
        params: requertParams.categoryDayTagParams,
        cb
      })
    }
  }

  const results = await promiseAsyncAuto(requertQueue)

  if (picksMenuList.length > 0) {
    results.picksMenuList = picksMenuList
    results.topBanner = Object.keys(bgBanner).length > 0 ? [bgBanner] : []
    results.picksLink = navInfoRes.picksLink?.[navInfoRes.pageId] || ''
  }

  return formatData(config, results)
}

// 处理返回参数
const formatData = (config, results) => {
  const catInfo = config.catInfo || {}

  if (results?.goods) {
    // bff处理
    const goods = results.goods ?? {}
    const filters = results.filters ?? {}
    const cloudTags = results.cloudTags ?? {}
    const dailyDates = results.dailyDates ?? {}
    const picksMenuList = results.picksMenuList ?? []
    const topBanner = results.topBanner ?? []
    const picksLink = results.picksLink ?? ''
  
    const bffProductsInfo = goods.code === '0' ?  { code: '0', ...goods.info } : {}
    // 日期选择数据
    const bffDailyDates = dailyDates.code === '0' ?  { code: '0', ...dailyDates.info } : {}
  
    let bffFilterInfo = {}
    const { type, sub_type } = catInfo
    const _type = type === 'store' ? sub_type : type
    if (_type === 'search') {
      bffFilterInfo = filters.code === '0' ? filters.info : {}
    } else if (['entity', 'selection', 'selling', 'daily_new', 'picks'].includes(_type)) {
      bffFilterInfo = generateRealSelectBffFilterInfo({
        bffCateAttrResult: filters.code === '0' ? filters : {},
        bffCloudTagResult: cloudTags.code === '0' ? cloudTags : {},
        bffDailyDatesResult: dailyDates.code === '0' ? dailyDates : {}
      }, catInfo)
    }

    const nodeRes = results.nodeRes

    const ret = {
      ...nodeRes,

      bffProductsInfo,
      bffFilterInfo,
      bffDailyDates,
      picksMenuList,
      topBanner,
      picksLink,
    }
    return ret
  } else {
    // 非融合直接返回
    return results.nodeRes
  }
}

