export const PRODUCT_LIST_URL_API = '/api/productList/info/get'


// 各个页面的bff xhr开关
// key 页面type
// value true推全走bff， string 页面对应的xhr开关 key
export const bffSwitchPageKeyMap = new Map([
  ['daily_new', 'daily_new_v2'],
  ['search', true],
  ['entity', true],
  ['selection', true],
  ['selling', 'selling_point_v1'],
  ['picks', 'bff_shein_picks_v1'],
])

// 各个页面的请求url
export const PRODUCT_LIST_REQUEST_URL_MAP = {
  // 搜索结果页
  SEARCH: {
    goods: '/product/get_products_by_keywords', // 商品数据
    filters: '/search/get_aggregation_filters', // 分类，属性，tsp筛选，图文导航，标签云
    bannerCard: '/search/card/non_category_by_keywords', // 搜索页顶部卡片
  },
  // 真实分类页
  REAL: {
    goods: '/category/real_category_goods_list', // 商品数据
    filters: '/category/real_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/real_category_tags', // 标签云
  },
  // 选品分类页
  SELEST: {
    goods: '/category/get_select_product_list', // 商品数据
    filters: '/category/select_category_attr_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/select_category_tags', // 标签云
  },
  SELLING: {
    goods: '/category/get_tags_goods_list', // 商品数据
    filters: '/category/get_tags_goods_list_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/selling_category_tags', // 标签云
  },
  // 信息流落地页
  PICKED: {
    goods: '/product/recommend/information_flow_selected_products', // 商品数据
    filters: '/product/recommend/information_flow_selected_filter', // 分类，属性，tsp筛选
    cloudTags: '/product/recommend/information_flow_selected_tags', // 标签云
  },
  // shein picks
  SHEIN_PICKS: {
    goods: '/category/get_products_by_discount_list',  // 商品数据
    filters: '/category/get_discount_list_filter',   // 分类，属性，tsp筛选
    cloudTag: '/category/discount_category_tags',   // 标签云
    navTabs: '/category/get_discount_cat',  // tab数据
  },
  USER_BEHAVIOR: {
    goods: '/category-api/recommend/get-user-behavior-products', // 商品数据
  },
  FEEDBACK_REC: {
    goods: '/product/recommend/feedback_products', // 商品数据
    filters: '/product/recommend/feedback_filter', // 分类，属性，tsp筛选
    cloudTags: '/product/recommend/feedback_tags', // 标签云
  },
  DAILY_NEW: {
    goods: '/category/category_daily_new', // 商品数据
    filters: '/category/get_daily_new_filter', // 分类，属性，tsp筛选
    cloudTag: '/category/daily_new_category_tags', // 标签云
  },
  COMMON: {
    categoryDay: '/category/select_category_day',
    showRecentDays: '/category/v2/category_daily_show_recent_days',
    buyboxEntrance: '/recommend/buybox/buybox_entrance',  // buybox入口
    rankListComponent: '/category/rank_list_component', // 榜单坑位请求
    cccCommonComponent: '/ccc/common_component', // 公共组件
    discountChannelComp: '/recommend/discount/channel_component',
    sheinClubInfo: '/user-api/prime/info',
  },
  DISCOUNT: {
    goods: '/recommend/discount/channel_landing_page',
    tabs: '/recommend/discount/landing_page_discount_tabs',
  },
}

export const ROUTE_REGEX = {
  search: /pdsearch\/([^\/]+)\/?$/,
  real: /-c-(\d+)\.html$/,
  select: /((\w+)\/)?[^/]+-sc-([A-Za-z0-9]+)\.html$/,
  selling: /sellingpoint-(\d+)-(\d+)-.+\.html$/,
  picked: /picked-info-products\.html$/,
  pickedProducts: /picked-products\.html$/,
  sheinPicks: /\/([a-z0-9]+-picks|romwe-[a-z]+|promo-discount)\.html$/,
  feedbackRec: /feedback-rec-products\.html$/,
  daily_new: /daily-new\.html$/,
}
