import { fixSearchSource } from './bffParamsHandle/utils.js'

/**
 * @description 获取页面请求参数
 * @returns {Object} 请求参数 { type, routeId, sub_type }
 * */
function getFetchListJsonParams({ catInfo, searchKeywords }) {
  if (!catInfo.type) return {}

  let routeId
  let sub_type
  switch (catInfo.type) {
    case 'store':
      return {
        store_code: catInfo.store_code,
        type: catInfo.sub_type,
        routeId: catInfo.select_id,
      }
    case 'entity':
      routeId = catInfo.entity_id
      break
    case 'virtual':
      routeId = catInfo.virtual_id
      break
    case 'selection':
      routeId = catInfo.select_id
      sub_type = catInfo.sub_type
      break
    case 'selling':
      routeId = catInfo.tag_val_id
      break
    case 'search':
      routeId = Number(searchKeywords.is_suggest_res)
        ? searchKeywords.suggest_words
        : searchKeywords.origin_words
      break
  }
  return { type: catInfo.type, routeId, sub_type }
}


/**
 * @description 获取请求参数
 * @returns {Object} 请求参数
 **/
export function getWebFetchParams({
  searchKeywords,
  catInfo,
  atomicParams,
  viewedGoods = [],
  request
}) {
  const { query = {}, type: requestType } = request
  const data = {
    ...getFetchListJsonParams({ catInfo, searchKeywords }),
    ...query,
    requestType,
    // 双语搜索后续请求需带上强制标记
    force_suggest: searchKeywords.double_lang_correct === '1', 
    // 是否请求付费会员, 在前端存起来, 每次请求的时候带给后端 (前端维护状态比后端容易)
    reqSheinClub: atomicParams.reqSheinClub,
    isPaid: atomicParams.isPaid,
    viewed_goods: viewedGoods.join()
  }
  // 抹掉空参
  Object.keys(data).forEach(key => {
    const needRemainZeroValue =
      ['min_price', 'max_price', 'isPaid', 'sourceStatus'].includes(key) &&
      Number(data[key]) === 0
    if (needRemainZeroValue) return
    !data[key] && delete data[key]
  })

  // data.type === 'search' && 前端新增搜索词类型传参search_source)
  if (data.type === 'search') {
    data.search_source = fixSearchSource(data)
  }
  return data
}
