
// 请求参数处理
// 根据 switchBff 判断是否融合走不同链路
import { bffSwitchPageKeyMap } from '../const.js'

import { getWebFetchParams } from './webparamsHandle.js'
import {
  getSellingPointGenerateBffParams,
  getSearchGenerateBffParams,
  getSelectionGenerateBffParams,
  getEntityGenerateBffParams,
  getStoreSearchGenerateBffParams,
  getStoreSelectionGenerateBffParams,
  getDailyNewGenerateBffParams,
  getSheinPicksGenerateBffParams,

} from './bffParamsHandle/index.js'

import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'

/**
 * 判断当前请求类型，返回相关参数
 * @param {
 *  searchKeywords: Object, // 搜索词相关
 *  catInfo: Object,        // 请求参数
 *  atomicParams: Object,   // 原子参数
 *  viewedGoods: Array,     // 已浏览商品集合
 *  request: Object         // 请求参数
 * } options 
 */
export const getFetchParams = (options) => {
  const {
    catInfo
  } = options
  const { type,  sub_type } = catInfo || {}
  let productFilterSwitchBff
  if(type === 'store'){ // 店铺 走bff
    productFilterSwitchBff = true
  }else{
    const productFilterBffKey = bffSwitchPageKeyMap.get(type)
    if (typeof productFilterBffKey === 'string') {
      productFilterSwitchBff = isSwitchBffApiVersion([productFilterBffKey])?.[productFilterBffKey]
    } else {
      productFilterSwitchBff = !!productFilterBffKey
    }
  }
  let paramsData = {
    // node主接口
    nodeParams: getWebFetchParams(options) || {},

    // bff的请求参数
    goodsParams: null,
    filterParams: null,
    cloudTagParams: null,
    categoryDayTagParams: null,
    navParams: null
  }

  // bff请求
  if (productFilterSwitchBff) {
    // 切掉主接口商品和筛选请求
    paramsData.nodeParams.useBffProducts = true
    paramsData.nodeParams.useBffFilters = true

    const useBffCccConfig = isSwitchBffApiVersion(['ccc_config'])?.ccc_config
    paramsData.nodeParams.useBffCccConfig = useBffCccConfig

    switch (type) {
      case 'selling': {
        paramsData = Object.assign(paramsData, getSellingPointGenerateBffParams(options), { method: 'GET' })
        break
      }
      case 'daily_new':
        paramsData = Object.assign(paramsData, getDailyNewGenerateBffParams(options))
        break
      case 'search':
        paramsData = Object.assign(paramsData, getSearchGenerateBffParams(options))
        break
      case 'entity':
        paramsData = Object.assign(paramsData, getEntityGenerateBffParams(options))
        break
      case 'selection':
        paramsData = Object.assign(paramsData, getSelectionGenerateBffParams(options))
        break
      case 'picks':
        paramsData = Object.assign(paramsData, getSheinPicksGenerateBffParams(options))
        break
      case 'store':
        if (sub_type === 'search') {
          paramsData = Object.assign(paramsData, getStoreSearchGenerateBffParams(options))
        } else {
          paramsData = Object.assign(paramsData, getStoreSelectionGenerateBffParams(options))
        }
        break
    }
  }

  return paramsData
}
