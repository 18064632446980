/**
 * @file 参考controllers/product_list_bff/daily_new/index.js
 */

import dayjs from 'dayjs'
import { getCommonParams  } from './common.js'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'

const getGoodsParams = (options) => {
  const { catInfo, request } = options
  const query = request.query || {}

  const { 
    newSrcTabPageId,
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    urlMallCode,
    filterMallCode,
    sort,
    source,
    sourceStatus,
  } = getCommonParams(options)

  let params = {
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    mall_code_list: filterMallCode || urlMallCode,
    src_tab_page_id: newSrcTabPageId,
    entranceType: catInfo.entranceType || '',
    sort: sort || 0,
    source,
    styleType: query.styleType || '',
    source_status: sourceStatus || '',
    page: query.page || 1,
    limit: catInfo.limit || 120,
    filter_goods_infos: '',
    adp: getGoodsAdp(query),
    srctype: 'other',
    page_name: 'page_daily_new',
    daily: query.daily,
    _type: 'daily_new',
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 筛选相关
const getFilterParams = (options) => {
  const { request } = options
  const query = request.query || {}

  const {
    quickship,
    filter,
    catId,
    filterTagIds,
    filterBrandIds,
    maxPrice,
    minPrice,
    tagIds,
    chooseIds,
    urlMallCode,
    filterMallCode,
  } = getCommonParams(options)

  let params = {
    quickship,
    filter,
    filterBrandIds,
    cat_id: catId,
    choosed_ids: chooseIds, // 已选中的分类id和其所有父类id用逗号拼接
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: filterMallCode || urlMallCode,
    styleType: query.styleType || '',
    showAllCategory: '1',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    daily: query.daily,
    page_name: 'page_daily_new',
    _type: 'daily_new'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = (options) => {
  const { request } = options
  const query = request.query || {}

  const { 
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
  } = getCommonParams(options)

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    quickship,
    filter,
    cat_id: catId,
    filter_tag_ids: filterTagIds,
    choosed_tag,
    max_price: maxPrice,
    min_price: minPrice,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    styleType: query.styleType || '',
    kidRandom: query.kid_random || '', // 避免缓存
    daily: query.daily,
    page_name: 'page_daily_new',
    _type: 'daily_new'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云中的date标签数据
const getCategoryDayTagParams = (options) => {
  const params = getFilterParams(options)

  const time = dayjs(Date.now())
  const today = time.format('YYYY-MM-DD')

  return {
    ...params,
    today,
    choosed_ids: undefined,
  }
}

export const getDailyNewGenerateBffParams = (options) => {
  return {
    goodsParams: getGoodsParams(options),
    filterParams: getFilterParams(options),
    cloudTagParams: getCloudTagParams(options),
    categoryDayTagParams: getCategoryDayTagParams(options),
  }
}
